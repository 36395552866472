import React, {useEffect, useState} from "react";
import AddItemButton from "./AddItemButton";
import '../Table.css'
import {axiosGetAuth} from "../../common/AxiosUtils";
import {ITEM_BASE_URL} from "../../common/Constants";
import ErrorElement from "../../common/ErrorElement";
import Loader from "../../common/Loader";

import {itemTableGenerator, nonOwnerItemTableGenerator} from "../../common/TableUtils";
import {removeTokenCookie, removeTokenFromLocalStorage} from "../../common/token/TokenHandling";


async function getItemData(listId) {
    return await axiosGetAuth(ITEM_BASE_URL + "/v1/list/?id=" + listId)
}


function ItemTable() {

    const [items, setItems] = useState([])
    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)


    let path = window.location.pathname
    let listId = path.slice(6);
    useEffect(() => {
        setLoading(true)
        getItemData(listId).then(async resp => {
            if (resp.reqError) {
                setError(true);
                setErrorMessage(resp.reqRespond.data)
                if (resp.reqRespond.status === 401) {
                    alert("Session is expired please login again")
                    removeTokenCookie()
                    nav("/login")
                } else {
                    alert("An Unexpected Error has occurred please refresh or try again later")
                }
            } else {
                setItems(resp.reqRespond.data)
                setError(false)
            }
        })
        setLoading(false)
    }, []);

    if (error) {
        return <ErrorElement message={errorMessage}/>
    }
    if (items.owner === true) {
        return [error && <ErrorElement message={errorMessage}/>, loading && <Loader/>, itemTableGenerator(items,listId),
            <AddItemButton listName={listId}/>]
    } else {
        return [error && <ErrorElement message={errorMessage}/>, loading &&
        <Loader/>, nonOwnerItemTableGenerator(items)]
    }
}

export default ItemTable;