import React, {useEffect, useState} from "react";
import Modal from 'react-modal';
import {axiosPostAuth} from '../../common/AxiosUtils'
import {ITEM_ADD_URL} from "../../common/Constants";
import {customStyles} from "../../common/Modal/ModalStyle";
import ErrorElement from "../../common/ErrorElement";
import Loader from "../../common/Loader";




function AddItemButton(props) {

    console.log(props)
    const [modalIsOpen, setIsOpen] = useState(false);
    const [itemUrlObject, setItemUrlObject] = useState({
        "url": "",
        "listId":props.listName
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        setError(false)
    }


    const submit = async e => {
        setLoading(true)
        console.log(itemUrlObject)
        let submitItemRequest = await axiosPostAuth(ITEM_ADD_URL, itemUrlObject);
        setLoading(false)
        if (submitItemRequest.reqError) {
            setError(true)
            setErrorMessage(submitItemRequest.reqRespond.data)
        } else {
            closeModal()
            console.log(itemUrlObject)
            alert("Added item url successfully ")
            window.location.reload();
        }
    }

    return (
        <div>
            <button id={"add-item-button"} onClick={openModal}>Add Item</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {error && <ErrorElement message={errorMessage} />}
                {loading && <Loader/>}
                <div>
                    <h1>Add Item</h1>

                    <label htmlFor="urlInput">Input URL of Item:</label>
                    <input name="urlInput" id="urlInput"
                           onChange={e => setItemUrlObject({...itemUrlObject, url: e.target.value})}/>
                </div>
                <button onClick={closeModal}>Close</button>
                <button onClick={submit}>Submit</button>
            </Modal>
        </div>
    );

}

export default AddItemButton;