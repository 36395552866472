import React, {useState} from "react";
import {useNavigate} from "react-router-dom"
import * as Constants from '../common/Constants.js'
import {FORGOTTEN_PASSWORD_URL} from '../common/Constants.js'
import "./loginV2.css";
import "../common/LoadingSpinner.css"
import {axiosPostJson} from "../common/AxiosUtils";
import {removeTokenCookie} from "../common/token/TokenHandling";


function ForgottenPasswordPage() {
    const [userData, setUserData] = useState({
        "email": "",
    });
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    let nav = useNavigate();
    const handleSubmit = async e => {
        e.preventDefault();
        setError(false)
        setLoading(true)
        if (userData.email.length === 0) {
            setError(true)
            setLoading(false)
            removeTokenCookie()
            return;
        }
        let loginPost = await axiosPostJson(FORGOTTEN_PASSWORD_URL, {
            email: userData.email,
            password: userData.password
        });

        if (loginPost.reqError) {
            setError(true)
            removeTokenCookie()
        }
        setLoading(false)
        setError(false)
        if (loginPost.reqError === false) {
            alert(loginPost.reqRespond.data)
        }
    };

    const errorMessage = (<div className="error">
        <p1> Incorrect Login Details Try Again</p1>
    </div>);

    const loader = <div className="loading"/>

    // JSX code for login form
    const renderForm = (
        <div className="form">
            {error && errorMessage}
            {loading && loader}
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Email </label>
                    <input type="text" name="uname" required
                           onChange={e => setUserData({...userData, email: e.target.value})}/>
                </div>
                <div className="button-container">
                    <input type="submit"/>
                </div>
            </form>
        </div>
    );

    return (
        <div className="app">
            <div className="forgottenPassword-form">
                <div className="title">Forgot Password</div>
                {renderForm}
            </div>
        </div>
    );
}

export default ForgottenPasswordPage;