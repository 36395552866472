import React from "react";
import RemoveItemButton from "../dashboard/item/RemoveItemButton";


const itemTableHeader = <tr>
    <th/>
    <th>Item</th>
    <th>Base URL</th>
    <th>URL</th>
    <th>Price</th>
</tr>;

const itemTableBody = (data,listId) => {
    return data.itemEntityList.map((item) => {
        console.log(item)
        let itemDataList = item.itemDataList;
        let latestName = ''
        let latestPrice;
        if (itemDataList != null) {
            let latestEntry = itemDataList[itemDataList.length - 1]
            latestName = latestEntry.name;
            if (latestName == null) {
                latestName = "No Name Captured"
            }
            latestPrice = latestEntry.price;
            if (latestPrice == null || latestPrice === 0) {
                latestPrice = "No Price Captured"
            } else {
                latestPrice = "£" + latestPrice
            }
        }
        return (<tr key={item.id}>
                <td>
                    <RemoveItemButton id={item.id} listId={listId}/>
                </td>
                <td>{latestName}</td>
                <td>/</td>
                <td><a href={item.url}>{item.url}</a></td>
                <td>{latestPrice}</td>
            </tr>
        );
    });
}

export const itemTableGenerator = (data,listId) => {
    if (data === undefined || data.itemEntityList === undefined || data.itemEntityList.length === 0) {
        return <div></div>
    }
    return <table>
        <thead>{itemTableHeader}</thead>
        <tbody>{itemTableBody(data,listId)}</tbody>
    </table>;
}


export const nonOwnerItemTableGenerator = data => {
    if (data === undefined || data.itemEntityList === undefined || data.itemEntityList.length === 0) {
        return <div id="empty-list">List is Empty</div>
    }
    return <table>
        <thead>{nonOwnerItemTableHeader}</thead>
        <tbody>{nonOwnerItemTableBody(data)}</tbody>
    </table>;
}



const nonOwnerItemTableHeader = <tr>
    <th>Item</th>
    <th>Base URL</th>
    <th>URL</th>
    <th>Price</th>
</tr>;

const nonOwnerItemTableBody = (data) => {
    return data.itemEntityList.map((item) => {
        let itemDataList = item.itemDataList;
        let latestName = ''
        let latestPrice;
        if (itemDataList != null) {
            let latestEntry = itemDataList[itemDataList.length - 1]
            latestName = latestEntry.name;
            if (latestName == null) {
                latestName = "No Name Captured"
            }
            latestPrice = latestEntry.price;
            if (latestPrice == null || latestPrice === 0) {
                latestPrice = "No Price Captured"
            } else {
                latestPrice = "£" + latestPrice
            }
        }
        return (<tr key={item.id}>
                <td>{latestName}</td>
                <td>/</td>
                <td><a href={item.url}>{item.url}</a></td>
                <td>{latestPrice}</td>
            </tr>
        );
    });
}

