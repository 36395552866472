import React, {useState} from "react";
import Modal from 'react-modal';
import {axiosDelete, axiosPut} from '../../common/AxiosUtils'
import {LIST_URL} from "../../common/Constants";
import {customStyles} from "../../common/Modal/ModalStyle";
import ErrorElement from "../../common/ErrorElement";
import Loader from "../../common/Loader";

function EditListButton(props) {

    let listId = props.listId;
    console.log(props)

    const [modalIsOpen, setIsOpen] = useState(false);
    const [itemUrlObject, setItemUrlObject] = useState({
        listId:listId,
        isListPublic: props.isPublic,
        newName: null })
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        setError(false)
    }


    const submit = async e => {
        setLoading(true)
        let submitItemRequest = await axiosPut(LIST_URL, itemUrlObject);
        setLoading(false)
        if (submitItemRequest.reqError) {
            setError(true)
            setErrorMessage(submitItemRequest.reqRespond.data)
        } else {
            closeModal()
            console.log(itemUrlObject)
            alert("Added item url successfully ")
            window.location.reload();
        }
    }

    function setCheckboxValue(e) {
        if (itemUrlObject.isListPublic) {
            setItemUrlObject({...itemUrlObject, isListPublic: false})
        } else {
            setItemUrlObject({...itemUrlObject, isListPublic: true})
        }
    }

    const handleDelete = async e => {
        setLoading(true)
        let submitItemRequest = await axiosDelete(LIST_URL + "/" + listId);
        setLoading(false)
        if (submitItemRequest.reqError) {
            setError(true)
            setErrorMessage(submitItemRequest.reqRespond.data)
        } else {
            closeModal()
            alert("List removed successfully")
            window.location.reload();
        }
    }

    return (
        <div>
            <button id={"edit-list-button"} onClick={openModal}>Edit List</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {error && <ErrorElement message={errorMessage}/>}
                {loading && <Loader/>}
                <div>
                    <h1>Edit List: "{props.listName}" </h1>

                    <label htmlFor="urlInput">Input New Name:</label>
                    <input name="urlInput" id="urlInput"
                           onChange={e => setItemUrlObject({...itemUrlObject, newName: e.target.value})}/>
                    <br/>
                    <label htmlFor="urlInput">Make Public:</label>

                    <input name="urlInput" id="urlInput" type={"checkbox"} defaultChecked={itemUrlObject.isListPublic}
                           onChange={e => setCheckboxValue(e)}/>
                </div>
                <br/>
                <button onClick={closeModal}>Close</button>
                <button onClick={submit}>Submit</button>
                <button class={"delete-button"} onClick={handleDelete}>Delete List</button>
            </Modal>
        </div>
    );

}

export default EditListButton;