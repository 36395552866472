import React from "react";
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from "./dashboard/Dashboard.js";
import Login from "./login/login.js";
import ProtectedRoute from "./common/route/ProtectedRoute";
import VerifyAccount from "./login/verifyAcccount";
import SignUp from "./login/SignUp";
import ItemTable from "./dashboard/item/ItemTable";
import Homepage from "./home/Homepage";
import {publicHeader} from "./common/header/Header";
import PublicRoute from "./common/route/PublicRoute";
import ForgottenPasswordPage from "./login/ForgottenPasswordPage";
import NewPasswordPage from "./login/NewPasswordPage";
import AboutUs from "./home/AboutUs";


function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <header className="App-header">
                    <Routes>
                        <Route exact path="/dashboard"
                               element={<ProtectedRoute redirectPath={"/login"} children={<Dashboard/>}/>}/>
                        <Route exact path="/verify"
                               element={<ProtectedRoute redirectPath={"/login"} children={<VerifyAccount/>}/>}/>
                        <Route path="/list/:listId"
                               element={<ProtectedRoute redirectPath={"/login"} children={<ItemTable/>}/>}/>
                        <Route exact path="/"
                               element={<PublicRoute redirectPath={"/"} children={<Homepage/>}/>}/>
                        <Route exact path="/login"
                               element={<PublicRoute redirectPath={"/"} children={<Login/>}/>}/>
                        <Route exact path="/signUp"
                               element={<PublicRoute redirectPath={"/"} children={<SignUp/>}/>}/>
                        <Route exact path="/forgottenPassword"
                               element={<PublicRoute redirectPath={"/"} children={<ForgottenPasswordPage/>}/>}/>
                        <Route exact path="/newPassword"
                               element={<PublicRoute redirectPath={"/"} children={<NewPasswordPage/>}/>}/>
                        <Route exact path="/aboutUs"
                               element={<PublicRoute redirectPath={"/"} children={<AboutUs/>}/>}/>
                        <Route exact path="*"
                               element={[publicHeader(),
                                   <p>There's nothing here: 404!</p>]}/>
                    </Routes>
                </header>
            </div>
        </BrowserRouter>
    );
}

export default App;
