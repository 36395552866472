import React, {useEffect, useState} from "react";
import * as Constants from '../common/Constants'
import {axiosGetAuth} from '../common/AxiosUtils'
import ListTables from './list/ListTables.js'
import "../common/LoadingSpinner.css"
import {removeTokenCookie, removeTokenFromLocalStorage} from "../common/token/TokenHandling";
import {useNavigate} from "react-router-dom";
import WatchListTable from "./list/WatchlistTable";

async function getItemData() {
    return await axiosGetAuth(Constants.LIST_URL + "/all")
}

function Dashboard() {

    const [lists, setLists] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    let nav = useNavigate()
    useEffect(() => {
        setLoading(true)

        getItemData().then(async resp => {
            if (resp.reqError) {
                setError(true);
                setLists([])
                console.log(resp.reqRespond.status)
                if (resp.reqRespond.status === 401) {
                    alert("Session is expired please login again")
                    removeTokenCookie()
                    nav("/login")
                } else {
                    alert("An Unexpected Error has occurred please refresh or try again later")
                }
            } else {
                setLists(resp.reqRespond.data)
                setError(false)
            }
        })
        setLoading(false)
    }, []);


    let errorElement = <div>Error</div>


    console.log(error)
    console.log(lists)
    //TODO table
    //WatchList for watching other users public lists
    //ListTable to have link to item data
    //ListTable.js ()props to contain the userData
    //Item.js () props to contain item object - have a graph
    //Add Button
    //Edit Button
    return <div id={"dashboard"}>
        {error && errorElement}
        {loading && <div className="loading"/>}
        {/* TODO <SideBar key="list-side-bar" props={lists}/> SIDEBAR to appear when in list*/}
        <ListTables key="item-table" props={lists}/>
        <WatchListTable key="watch-table" props={lists}/>
    </div>


}

export default Dashboard;
