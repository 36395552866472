import React from "react";

function AboutUs() {

    return (
        <div id={"about-us-div"}>
            About Us
        </div>)


}

export default AboutUs;