import React from "react";
import "./Homepage.css"
import {useNavigate} from "react-router-dom"
import Cookies from "universal-cookie";
import {removeTokenCookie, setTokenCookie} from "../common/CookieHandler";

var string = "\n" +
    "Welcome to our website, the ultimate hub for managing wishlists," +
    " tracking prices, and seamlessly sharing inspiration with friends and the community," +
    " catering to fashion bloggers, tech enthusiasts, bargain hunters, and for competitor reconnaissance."

var fromChatgpt = "Manage Wishlists:\n" +
    "Welcome to our website, the ultimate hub for managing wishlists. Easily organize and access your desired items, whether you're a fashion blogger, tech enthusiast, or bargain hunter. Stay organized and never miss out on your favorite products.\n" +
    "\n" +
    "Share Wishlists and Follow:\n" +
    "Share your wishlists with friends and the community, or follow others for inspiration. Connect and discover new products and trends. Let your wishlist collection inspire others and find inspiration from fellow wishlisters.\n" +
    "\n" +
    "Price Tracking:\n" +
    "Track prices and stay informed about changes in real-time. Never miss a great deal again and make informed purchasing decisions. Whether you're monitoring prices for fashion, tech, or any other category, our platform keeps you updated and helps you save."
var manageWishlists = "Welcome to our website, the ultimate hub for managing wishlists. Easily organize and access your desired items, whether you're a fashion blogger, tech enthusiast, or bargain hunter. Stay organized and never miss out on your favorite products."

var share = "Share your wishlists with friends and the community, or follow others for inspiration. Connect and discover new products and trends. Let your wishlist collection inspire others and find inspiration from fellow wishlisters."

var priceTracking = "Track prices and stay informed about changes. Never miss a great deal again and make informed purchasing decisions. Whether you're monitoring prices for fashion, tech, or any other category, our platform keeps you updated and helps you save."

function Homepage() {
    let nav = useNavigate();
    return (
        <div id={"main-div"}>
            {/*Home Page*/}
            {/*Image*/}
            <div className={"section"}>
                <div className={"text-section"}>
                    <h1>Manage Wishlist's</h1>
                    <p>{manageWishlists}</p>
                    <button id="signUp-button" onClick={e => nav("/SignUp")}>Sign Up Now
                    </button>
                </div>
                <div className={"image-section"}>
                    <img src={require('./list.png')}
                         width="400px" height="400px"/>
                </div>
            </div>

            <div className={"section"}>
                <div className={"image-section"}>
                    <img src={require('./multipleLists.png')}
                         width="600px" height="350px"/>
                </div>
                <div className={"text-section"}>
                    <h1>Share Wishlists and Follow</h1>
                    <p>{share}</p>
                    <button id="signUp-button" onClick={e => nav("/SignUp")}>Sign Up Now
                    </button>
                </div>
            </div>

            <div className={"section"}>
                <div className={"text-section"}>
                    <h1>Price Tracking</h1>
                    <p>{priceTracking}</p>
                    <button id="signUp-button" onClick={e => nav("/SignUp")}>Sign Up Now
                    </button>
                </div>
                <div className={"image-section"}>
                    <img src={require('./tag.png')}
                         width="400px" height="400px"/>
                </div>
            </div>

            {/*footer*/}
        </div>)


}

export default Homepage;