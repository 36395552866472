export const isSessionExpired = function isSessionExpired(reqError) {
    if (reqError) {
        return reqError.data === "Session Expired";
    } else {
        return false
    }
}

export const isUserActive = function isUserActive(reqError) {
    if (reqError) {
        return reqError.data === "User is already active";
    } else {
        return false
    }
}