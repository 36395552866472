import "./Header.css"
import {removeTokenCookie} from "../token/TokenHandling";

export const publicHeader = () => {
    return <div className="header">
        <a href="/" className="logo"><img src={require('./logo.PNG')}
                                          width="200px" height="px"/> </a>
        <div className="header-right">
            <a className="active" href="/">Home</a>
            <a href="/login">Login</a>
            <a href="/signUp">Sign Up</a>
            <a href="/aboutUs">About Us</a>
        </div>
    </div>
}

export const privateHeader = () => {
    return <div className="header">
        <a href="/" className="logo"><img src={require('./logo.PNG')}
                                          width="200px" height="px"/> </a>
        <div className="header-right">
            <a className="active" href="dashboard">Home</a>
            <a href="/dashboard">Dashboard</a>
            <a onClick={signOut}>Sign-Out</a>
        </div>
    </div>
}

const signOut = () => {
    removeTokenCookie()
    alert("Logged out")
    window.location.reload()
}
