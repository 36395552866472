import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom"
import {NEW_PASSWORD_URL} from '../common/Constants.js'
import "./loginV2.css";
import "../common/LoadingSpinner.css"
import {axiosPostAuth} from "../common/AxiosUtils";
import {removeTokenCookie, setTokenCookie} from "../common/token/TokenHandling";

const PATH = "/newPassword"

function NewPasswordPage() {

    let nav = useNavigate();
    const token = window.location.href.split(PATH + "?");
    const [error, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleRedirect = async (token, nav) => {
        if (token[1] !== null && token[1] !== undefined) {
            setTokenCookie(token[1])
            nav(PATH)
        } else {
            setError(true)
        }
    }

    useEffect(() => {
        handleRedirect(token, nav).then(r => r);
    },);

    const [userData, setUserData] = useState({
        "password": "",
        "confirmPassword": "",
    });

    const handleSubmit = async e => {
        if (userData.password !== userData.confirmPassword) {
            setErrorMessage("Password do not match")
            setError(true)
            setLoading(false)
            return;
        }
        e.preventDefault();
        setError(false)
        setLoading(true)
        let loginPost = await axiosPostAuth(NEW_PASSWORD_URL, {
            password: userData.password
        });
        if (loginPost.reqError) {
            setError(true)
            removeTokenCookie()
        }
        setLoading(false)
        setError(false)
        if (loginPost.reqError === false) {
            removeTokenCookie()
            alert("Password Changed Successfully")
            nav("/login")
        }
    };

    const errMessage = (<div className="error">
        <p1> {errorMessage}</p1>
    </div>);

    const loader = <div className="loading"/>

    // JSX code for login form
    const renderForm = (
        <div className="form">
            {error && errMessage}
            {loading && loader}
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Password </label>
                    <input type="password" name="pass" required
                           onChange={e => setUserData({...userData, password: e.target.value})}/>
                </div>
                <div className="input-container">
                    <label>Confirm Password </label>
                    <input type="password" name="confirmPass" required
                           onChange={e => setUserData({...userData, confirmPassword: e.target.value})}/>
                </div>
                <div className="button-container">
                    <input type="submit"/>
                </div>
            </form>
        </div>
    );

    return (
        <div className="app">
            <div className="forgottenPassword-form">
                <div className="title">Enter New Password</div>
                {renderForm}
            </div>
        </div>
    );
}

export default NewPasswordPage;