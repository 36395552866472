import React, {useState} from "react";
import Modal from 'react-modal';
import {axiosPostAuth} from '../../common/AxiosUtils'
import {WATCHLIST_ADD_URL, LIST_URL} from "../../common/Constants";
import Loader from "../../common/Loader";
import {customStyles} from "../../common/Modal/ModalStyle";
import ErrorElement from "../../common/ErrorElement";


function AddWatchListButton(props) {

    const [modalIsOpen, setIsOpen] = useState(false);
    const [listURL, setListURL] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");



    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
        setError(false)
    }


    const submit = async e => {


        let path = listURL.split("/list/")
        let listName = path[1];


        console.log(listName)
        let id;
        if (listName === undefined || listName.length===0){
            setError(true)
            setErrorMessage("Input is invalid")
            return;
        }

        setLoading(true)
        let submitItemRequest = await axiosPostAuth(WATCHLIST_ADD_URL, {listId: listName})
        setLoading(false)
        if (submitItemRequest.reqError) {
            setError(true)
            console.log(submitItemRequest)
            if (submitItemRequest.reqRespond===null){
                setErrorMessage("Unknown Error")
            } else {
                setErrorMessage(submitItemRequest.reqRespond.data)
            }
        } else {
            closeModal()
            console.log(listObject)
            alert("List added successfully")
            window.location.reload();
        }
    }

    return (
        <div>
            <button id={"add-list-button"} onClick={openModal}>Add Watch List</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {error && <ErrorElement message={errorMessage}/>}
                {loading && Loader}
                <div>
                    <h1>Add Item</h1>

                    <label htmlFor="list-input">Input List URL To Watch:</label>
                    <input name="list-input" id="list-input"
                           onChange={e => setListURL(e.target.value)}/>
                </div>
                <button onClick={closeModal}>Close</button>
                <button onClick={submit}>Submit</button>
            </Modal>
        </div>
    );

}

export default AddWatchListButton;