import React, {useState} from "react";
import '../Table.css'
import {axiosDelete} from "../../common/AxiosUtils";
import {removeTokenCookie} from "../../common/token/TokenHandling";
import {AiFillCloseCircle} from 'react-icons/ai'
import {ITEM_ADD_URL} from "../../common/Constants";

function RemoveItemButton(props) {

    const [error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const id = props.id;
    const listId = props.listId;

    const deleteRequest = () => {
        setLoading(true)
        axiosDelete(ITEM_ADD_URL + "?itemId=" + id + "&listId=" + listId)
            .then(async resp => {
                if (resp.reqError) {
                    setError(true);
                    setErrorMessage(resp.reqRespond.data)
                    if (resp.reqRespond.status === 401) {
                        alert("Session is expired please login again")
                        removeTokenCookie()
                        nav("/login")
                    } else {
                        alert("An Unexpected Error has occurred please refresh or try again later")
                    }
                } else {
                    alert("Item has been removed")
                    setError(false)
                    window.location.reload()
                }
                setLoading(false)
            })
    }

    return <button onClick={deleteRequest} className="btn"><AiFillCloseCircle/></button>
}

export default RemoveItemButton;