import React, {useState} from "react";
import {useNavigate} from "react-router-dom"
import {axiosPostLogin} from "../common/AxiosUtils";
import { setTokenCookie} from "../common/token/TokenHandling"
import * as Constants from '../common/Constants.js'
import {USER_URL} from '../common/Constants.js'
import "./loginV2.css";
import "../common/LoadingSpinner.css"

const LOGIN_URL = Constants.LOGIN_URL;

function SignUp() {
    // React States
    const [userData, setUserData] = useState({
        "email": "",
        "password": "",
        "confirmPassword": "",
        "username":""
    });
    const [error, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)

    let nav = useNavigate();
    const handleSubmit = async e => {
        e.preventDefault();
        console.log(userData)
        setError(false)
        setLoading(true)
        if (userData.email.length === 0 || userData.password.length === 0 || userData.password !== userData.confirmPassword) {
            setError(true)
            setLoading(false)
            return;
        }
        let loginPost = await axiosPostLogin(USER_URL, {
            email: userData.email,
            password: userData.password,
            username: userData.username
        });

        if (loginPost.isError) {
            if (loginPost.isError) {
                setError(true)
                if (loginPost.response.status === 400) {
                    setErrorMessage("Incorrect details! Please try again")
                } else {
                    setErrorMessage("Server is not currently Responding! please try again")
                }
            }
        }
        setLoading(false)
        if (loginPost.isError === false) {
            let token = loginPost.response.data.jwtToken
            setTokenCookie(token)
            if (loginPost.response.data.accountStatus !== "ACTIVE") {
                nav("/verify")
            }
        }
    }

    const errorElement = (<div className="error">
        <p1> {errorMessage}</p1>
    </div>);

    const loader = <div className="loading"/>

    // JSX code for login form
    const renderForm = (
        <div className="form">
            {error && errorElement}
            {loading && loader}
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Email</label>
                    <input type="text" name="email" required
                           onChange={e => setUserData({...userData, email: e.target.value})}/>
                </div>
                <div className="input-container">
                    <label>Password </label>
                    <input type="password" name="pass" required
                           onChange={e => setUserData({...userData, password: e.target.value})}/>
                </div>
                <div className="input-container">
                    <label>Confirm Password </label>
                    <input type="password" name="confirmPass" required
                           onChange={e => setUserData({...userData, confirmPassword: e.target.value})}/>
                </div>
                <div className="button-container">
                    <input type="submit"/>
                </div>
                <div>
                    <a className="form-link" onClick={e => nav("/login")}> Have an Account? Login from here! </a>
                </div>
            </form>
        </div>
    );

    return (
        <div className="app">
            <div className="signUp-form">
                {renderForm}
            </div>
        </div>
    );
}

export default SignUp;
