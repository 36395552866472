import React, {useState} from "react";
import {useNavigate} from "react-router-dom"
import {axiosPostLogin} from "../common/AxiosUtils";
import {removeTokenCookie, setTokenCookie} from "../common/token/TokenHandling"
import * as Constants from '../common/Constants.js'
import "./loginV2.css";
import "../common/LoadingSpinner.css"


const LOGIN_URL = Constants.LOGIN_URL;

function Login() {

    const [userData, setUserData] = useState({
        "email": "",
        "password": ""
    });
    const [error, setError] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [loading, setLoading] = useState(false)

    let nav = useNavigate();
    const handleSubmit = async e => {
        e.preventDefault();
        setError(false)
        setLoading(true)
        removeTokenCookie()
        if (userData.email.length === 0 || userData.password.length === 0) {
            setError(true)
            setLoading(false)
            return;
        }

        let loginPost = await axiosPostLogin(LOGIN_URL, {email: userData.email, password: userData.password});

        if (loginPost.isError) {
            setError(true)
            if (loginPost.response.status ===400) {
                setErrorMessage("Incorrect Login Details Try Again please try again or Sign up if you dont have an account")
            } else {
                setErrorMessage("Server is not currently Responding! please try again")
            }
        }

        setLoading(false)
        if (loginPost.isError === false) {
            let token = loginPost.response.data.jwtToken
            setTokenCookie(token)
            if (loginPost.response.data.accountStatus === "UNVERIFIED") {
                nav("/verify")
            } else if (loginPost.response.data.accountStatus === "ACTIVE") {
                nav("/dashboard")
            }
        }
    };

    const errorElement = (<div className="error">
        <p1> {errorMessage}</p1>
    </div>);

    const loader = <div className="loading"/>

    // JSX code for login form
    const renderForm = (
        <div className="form">
            {error && errorElement}
            {loading && loader}
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Email </label>
                    <input type="text" name="uname" required
                           onChange={e => setUserData({...userData, email: e.target.value})}/>
                </div>
                <div className="input-container">
                    <label>Password </label>
                    <input type="password" name="pass" required
                           onChange={e => setUserData({...userData, password: e.target.value})}/>
                </div>
                <div className="button-container">
                    <input type="submit"/>
                </div>
                <div>
                    <a className="form-link" onClick={e => nav("/SignUp")}> Don't have an account? Sign Up from
                        here! </a>
                </div>
                <div>
                    <a className="form-link" onClick={e => nav("/forgottenpassword")}> Forgotten Password? Reset from
                        here! </a>
                </div>
            </form>
        </div>
    );

    return (
        <div className="app">
            <div className="login-form">
                <div className="title">Login</div>
                {renderForm}
            </div>
        </div>
    );
}

export default Login;