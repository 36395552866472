import {useEffect, useState} from "react";
import {getTokenCookie} from "../token/TokenHandling.js";
import {privateHeader, publicHeader} from "../header/Header";

function PublicRoute(props) {

    const [tokenExists, setTokenExists] = useState(true);
    let children = props.children
    const checkToken = () => {
        let token = getTokenCookie()
        if (token === undefined || (token === null) ) {
            setTokenExists(false);
        } else {
            setTokenExists(true)
        }
    }
    useEffect(() => {
        checkToken()
    }, [tokenExists]);

    if (tokenExists === true) {
        return [privateHeader(), children]
    } else {
        return [publicHeader(), children]
    }

}


export default PublicRoute;