import {useEffect, useState} from "react";
import {getTokenCookie} from "../token/TokenHandling";
import {useNavigate} from "react-router-dom";
import {privateHeader} from "../header/Header";

function ProtectedRoute(props) {

    const [tokenExists, setTokenExists] = useState(true);
    let nav = useNavigate();
    let children = props.children
    const checkToken = () => {
        let token = getTokenCookie();
        console.log(token)
        if (token === null || token === undefined || token.trim().length === 0) {
            setTokenExists(false);
        } else {
            setTokenExists(true)
        }
    }
    useEffect(() => {
        checkToken()
        if (!tokenExists) {
            alert("Session is not valid - Please Login again")
            return nav(props.redirectPath)
        }
    }, []);

    return [privateHeader(), children];

}


export default ProtectedRoute;