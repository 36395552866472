import React from "react";
import '../Table.css'
import AddListButton from "./AddListButton";
import LinkElement from "../../common/LinkElement";
import EditListButton from "./EditListButton";
import AddWatchListButton from "./AddWatchListButton";

function ListTables(props) {
    let headers = <tr>
        <th>List</th>
        <th>Items</th>
        <th>Edit</th>
    </tr>;


    console.log(props)
    let data = props.props;

    if (props.props.length !== 0 && props.props.itemList.length > 0) {
        return [generateTable(data.itemList),
            <AddListButton/>]
    } else {
        return [
            <table>
                <thead>{headers}</thead>
            </table>,
            <AddListButton/>
        ]
    }

}

function generateTable(data) {
    let headers = <tr>
        <th>List</th>
        <th>Items</th>
        <th>Edit</th>
    </tr>;

    let rows = data.map((list) => {
        let listName = list.listName
        let itemCount = list.itemEntityList.length;
        let listId = list.id;

        return (<tr key={list.id}>
                <td><LinkElement elements={listName} to={"/list/" + listId}/></td>
                <td>{itemCount}</td>
                <td><EditListButton listId={list.id} listName={listName} isPublic={list.publicList}/></td>
            </tr>
        );
    });

    return <table>
        <thead>{headers}</thead>
        <tbody>{rows}</tbody>
    </table>;
}


function generateWatchlistTable(data) {
    let headers = <tr>
        <th>List</th>
        <th>Edit</th>
    </tr>;

    let rows = data.map((list) => {
        let listName = list.listName
        let itemCount = list.itemEntityList.length;
        let listId = list.id;

        return (<tr key={list.id}>
                <td><LinkElement elements={listName} to={"/list/" + listId}/></td>
                <td>{itemCount}</td>
                <td><EditListButton listId={list.id} listName={listName} isPublic={list.publicList}/></td>
            </tr>
        );
    });

    return <table>
        <thead>{headers}</thead>
        <tbody>{rows}</tbody>
    </table>;
}

export default ListTables;