import React, {useState} from "react";
import {useNavigate} from "react-router-dom"
import {axiosPostAuth,} from "../common/AxiosUtils";
import * as Constants from '../common/Constants.js'
import {NEW_VERIFICATION_CODE_URL, VERIFY_URL} from '../common/Constants.js'
import "./loginV2.css";
import "../common/LoadingSpinner.css"
import {isSessionExpired, isUserActive} from "../common/token/SessionUtils";


const LOGIN_URL = Constants.LOGIN_URL;

function VerifyAccount() {
    // React States
    const [verifCode, setVerifCode] = useState(0);
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    let nav = useNavigate();
    const handleSubmit = async e => {
        e.preventDefault();
        console.log(e.target.value)
        setError(false)
        setLoading(true)
        if (verifCode.length < 5) {
            setError(true)
            setLoading(false)
            return;
        }
        let loginPost = await axiosPostAuth(VERIFY_URL, {"verificationCode": verifCode});
        if (loginPost.reqError) {
            setError(true);
            if (isSessionExpired(loginPost.reqRespond)) {
                alert("Session has expired, please login again!")
                nav("/")
            } else if (isUserActive(loginPost.reqRespond)) {
                alert("User already has an active account")
                nav("/dashboard")
            }
        }
        setLoading(false)
        if (loginPost.reqError === false) {
            nav("/dashboard")
        }
    };

    const handleNewCodeRequest = async e => {
        let response = await axiosPostAuth(NEW_VERIFICATION_CODE_URL, {})
        setError(false)
        setLoading(true)
        if (response.reqError) {
            setError(true);
            if (isSessionExpired(response.reqRespond)) {
                alert("Session has expired, please login again!")
                nav("/")
            } else if (isUserActive(response.reqRespond)) {
                alert("User already has an active account")
                nav("/dashboard")
            } else {
                alert("Error generating new Verification Code, Please try again later")
            }
        }
        setLoading(false)
        if (response.reqError === false) {
            alert("Please wait for email and enter new verification")
        }
    }

    const errorMessageDiv = (<div className="error">
        <p1> Incorrect Code</p1>
    </div>);

    const loader = <div className="loading"/>

    // JSX code for login form
    const renderForm = (
        <div className="form">
            {error && errorMessageDiv}
            {loading && loader}
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Enter Verification Code </label>
                    <input type="number" name="verifCode" required
                           onChange={e => setVerifCode(e.target.value)}
                           pattern="[0-9]*"
                    />
                </div>

                <div className="button-container">
                    <input type="submit"/>
                </div>
            </form>
            <div className="button-container">
                <input type="button" value="Generate New Code" onClick={
                    handleNewCodeRequest
                }/>
            </div>
        </div>
    );

    return (
        <div className="app">
            <div className="verification-form">
                <div className="title">Verify Account</div>
                {renderForm}
            </div>
        </div>
    );
}

export default VerifyAccount;