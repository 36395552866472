import React from "react";
import '../Table.css'
import LinkElement from "../../common/LinkElement";
import AddWatchListButton from "./AddWatchListButton";


let headers = <tr>
    <th>List</th>
    <th>Item Count</th>
    <th>Remove</th>
</tr>;


function WatchListTable(props) {

    let data = props.props;
    if (data["watchlist"] !== undefined && data["watchlist"].length > 0) {
        return [generateWatchlistTable(data["watchlist"]), <AddWatchListButton/>]
    } else {
        return [<table>
            <thead>{headers}</thead>
        </table>, <AddWatchListButton/>]
    }
}

function generateWatchlistTable(data) {
    console.log(data)
    let rows = data.map((list) => {
        console.log(list)
        let listName = list.listName
        let itemCount = list.items;
        let listId = list.listId;
        let isAvailable = list.available;

        if (isAvailable){
            return (<tr key={listId}>
                <td><LinkElement elements={listName} to={"/list/" + listId}/></td>
                <td>{itemCount}</td>
                <td>
                    <button>Remove Icon</button>
                </td>
            </tr>);
        }
        
    });

    return <table>
        <thead>{headers}</thead>
        <tbody>{rows}</tbody>
    </table>;
}


export default WatchListTable;